<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="330"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon small>mdi-file-pdf-box</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title style="line-height: 1 !important;">
          <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
            style="padding: 10px 0;"
          />
          <br/>
          <v-switch small v-model="range" label="rango de fechas" hideDetails style="margin-bottom: 20px;" />
          <v-date-picker v-if="!loading" v-model="date" :range="range" color="primary" :locale="locale" />
          <small style="font-size: 10px;">{{typeof(date) === 'array' ? date.join(', ') : date}}</small>
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="loading"
            @click="dialog = false"
          >
          {{ $t('common.close', locale) }}
          </v-btn>
          <v-btn
            @click="handleExportPDF"
            color="primary"
            :disabled="loading"
          >
            PDF
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import api from '@/services/api'
export default {
  props: {
    locale: {
      type: String,
      required: true,
    },
    workspaceID: {
      type:  String,
      required: true,
    },
  },
  data () {
    return {
      dialog: false,
      date: new Date().toISOString().substr(0, 10),
      loading: false,
      range: false,
    }
  },
  watch: {
    range (v) {
      if (v) this.date = [
      ]
      else this.date = new Date().toISOString().substr(0, 10)
    },
    date () {
      if (this.range && this.date.length > 1 && new Date(this.date[1]) < new Date(this.date[0])) {
        this.date = [this.date[1], this.date[0]]
      }
      if (this.range && this.date.length > 1) {
        const date1 = new Date(this.date[0])
        const date2 = new Date(this.date[1])
        const diffTime = Math.abs(date2 - date1)
        const days = Math.floor(diffTime / (1000 * 60 * 60 * 24))
        if (days > 6) {
          alert('No puede superar los 7 d�as')
          this.date = [this.date[0]]
        }
      }
    },
  },
  methods: {
    handleExportPDF () {
      this.loading = true
      const url = this.range ? `v1/private/attendance/pdf/${this.workspaceID}/${this.date[0]}/${this.date[1]}` : `v1/private/attendance/pdf/${this.workspaceID}/${this.date}`
      api.getAllWithoutLimit ('booking', url, false, `&states=${this.showAll ? 'all' : ''}`)
        .then(response => {
          window.open(response, '_blank')
          this.loading = false
          this.dialog = false
        })
    },
  },
}
</script>
