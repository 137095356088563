<template>
  <div style="padding-top:20px;background-color: #272727; min-height: calc(100vh);">
    <list 
      v-if="workspaceID"
      :locale="locale" 
      :workspaceID="workspaceID"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import List from './list/Index'
export default {
  components: {
    List,
  },
  data: () => ({
  }),
  computed: {
    ...mapState('app',['locale', 'bookingWorkspace']),
    workspaceID () {
      return this.bookingWorkspace
    },
  },
}
</script>

