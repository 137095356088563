var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.headers)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"loading":_vm.loading,"headers":_vm.headers.filter(function (x) { return !x.expandData; }),"items":_vm.data,"items-per-page":_vm.itemsPerPage,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"ID","show-expand":"","dark":"","hide-default-footer":"","multi-sort":false,"sortBy":_vm.sortby,"sortDesc":_vm.sortdesc,"mobile-breakpoint":"800"},on:{"update:expanded":function($event){_vm.expanded=$event},"update:sort-by":_vm.handleSortBy,"update:sort-desc":_vm.handleSortDesc},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Registro de entrada")]),_c('v-spacer'),(_vm.perms && _vm.perms.indexOf('a5c7ba9b-a5c1-4338-9235-c8a6c842882b') >= 0)?_c('export-pdf',{attrs:{"locale":_vm.locale,"workspaceID":_vm.workspaceID}}):_vm._e(),(_vm.onSearch)?_c('search',{attrs:{"onSearch":_vm.onSearch,"showClear":true}}):_vm._e(),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.handleGetData}},[_vm._v(" refrescar ")])],1)]},proxy:true},{key:"item.ident",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"color":"#CCCCCC","text-decoration":"underline"},on:{"click":function($event){return _vm.handleCheckin(item)}}},[_vm._v(_vm._s(item.ident.name))])]}},{key:"item.notes",fn:function(ref){
var item = ref.item;
return [_c('note-form',{attrs:{"item":item,"locale":_vm.locale,"onRefresh":_vm.handleGetData}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{style:({ 'min-width': _vm.perms && _vm.perms.indexOf('512c8864-52ca-445d-8c31-2a2e45132942') >= 0 ? '50px' : null}),attrs:{"wrap":""}},[(_vm.perms && _vm.perms.indexOf('512c8864-52ca-445d-8c31-2a2e45132942') >= 0)?_c('v-flex',{attrs:{"xs6":""}},[_c('delete-item',{attrs:{"id":item.ID,"onDeleteItem":_vm.handleDeleteAttendance,"locale":_vm.locale,"icon":"true"}})],1):_vm._e(),_c('v-flex',{attrs:{"xs6":""}},[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.handleCheckin(item)}}},[_vm._v(" mdi-file-sign ")])],1)],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('expanded-item',{attrs:{"item":item,"locale":_vm.locale,"perms":_vm.perms}})],1)]}},{key:"footer",fn:function(){return [_c('pagination',{attrs:{"startIndex":_vm.startIndex,"itemsPerPage":_vm.itemsPerPage,"count":_vm.count,"onGetData":_vm.handleGetData,"locale":_vm.locale,"dark":true,"onChangeItemsPerPage":_vm.handleChangeItemsPerPage}})]},proxy:true}],null,false,3420352036)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }